<template>
    <bar-amount-by-date :labels="labels"
                        :datasets="datasets"/>
</template>

<script>
import moment from 'moment';
import BarAmountByDate from "./shared/BarAmountByDate";
import TicketsHelper from "../../helpers/TicketsHelper";
import {Vibrant} from "@/color_schemes/custom";

export default {
    components: {
        BarAmountByDate
    },
    props: {
        amounts: {
            type: Object,
            required: true
        },
        limit: {
            type: Number,
            default: 7
        }
    },
    methods: {
        getTicketsQuantityFromAmount(donation) {
            return TicketsHelper.getTicketsQuantityFromAmount(donation);
        }
    },
    computed: {
        latest() {
            return Object.values(this.amounts)
                .splice(-this.limit)
                .map(x => this.getTicketsQuantityFromAmount(x));
        },
        labels() {
            return Object.keys(this.amounts)
                .splice(-this.limit)
                .map(x => moment(x, "YYYYMMDD").format('DD/MM'));
        },
        datasets() {
            return [
                {
                    label: 'Bilhetes vendidos',
                    data: this.latest,
                    backgroundColor: Vibrant[0]
                }
            ];
        }
    }
}
</script>
