import {store} from '../stores/authStore.js';

export default class TicketsHelper {
    static _ticketPrice = null;

    static metadataName = 'bilhetes';

    static get ticketPrice() {
        if (!this._ticketPrice) {
            this._ticketPrice = store.getters._store_getAuthSettingsProp('ticket_price');
        }

        return this._ticketPrice;
    }

    static getTicketsQuantityFromDonation(donation) {
        return typeof donation.metadata !== 'undefined' &&
        typeof donation.metadata[this.metadataName] !== 'undefined'
            ? donation.metadata[this.metadataName].split(',').length
            : 0;
    }

    static getTicketsQuantityFromAmount(amount) {
        return amount / this.ticketPrice;
    }
}
