<template>
    <div class="donations" :key="forceKey">
        <div v-for="referral in topReferrals" :key="referral.key" class="donation">
            <strong class="badge badge-secondary quantity">
                {{ valueFormatter(referral.sum) }}
            </strong>

            <span class="prefix">
                indicação de
            </span>

            <span class="name">
                {{ referral.referral_name | formatFullName }}
            </span>

            <strong v-if="getMedadata(referral)" class="badge badge-info metadata">
                {{ getMedadata(referral) }}
            </strong>
        </div>
    </div>
</template>

<style scoped>
    div.donations {
        overflow-y: auto;
    }

    div.donation {
        color: #333;
    }

    div.donation .quantity {
        font-size: .8rem;
    }

    div.donation .prefix {
        font-size: .8rem;
        margin-left: 5px;
        margin-right: 5px;
        color: #555;
    }

    div.donation .name {
        font-size: .8rem;
    }

    div.donation .metadata {
        margin-left: 10px;
    }

    @media (min-width: 700px) {
        div.donation .prefix {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    @media (min-width: 1400px) {

        div.donation {
            margin-bottom: .5rem;
        }

        div.donation .quantity {
            font-size: 1rem;
        }

        div.donation .prefix {
            font-size: .9rem;
        }

        div.donation .name {
            font-size: 1rem;
        }
    }
</style>

<script>
    import "../../filters/default";
    import DataHelper from "../../helpers/DataHelper";

    export default {
        props: {
            referrals: {
                type: Array,
                required: true
            },
            limit: {
                type: Number,
                default: 5
            },
            metadata: {
                type: String,
                default: ''
            },
            valueFormatter: {
                type: Function,
                default: (v) => {
                    return v;
                }
            },
            metadataFormatter: {
                type: Function,
                default: (v) => {
                    return v;
                }
            }
        },
        data() {
            return {
                forceKey: 0,
                forceSeconds: 60
            }
        },
        methods: {
            setTimeInterval() {
                window.setInterval(() => {
                    this.forceKey = (new Date()).getMinutes();
                }, this.forceSeconds * 1000);
            },
            getMedadata(referral) {
                return this.metadata && referral['referral_' + this.metadata]
                    ? this.metadataFormatter(referral['referral_' + this.metadata], referral)
                    : '';
            }
        },
        computed: {
            topReferrals() {
                return DataHelper.getArrayTop(this.referrals, this.limit);
            }
        },
        created() {
            this.setTimeInterval();
        }
    }
</script>


