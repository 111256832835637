<template>
    <div :key="forceKey">
        <div v-for="donation in topDonations" :key="donation.key" class="mb-2 donation">
            <strong class="timestamp">{{ donation.timestamp | formatFromNow }}</strong>
            {{ donation.donor.name }}
            <strong class="badge badge-secondary quantity">
                {{ getTicketsQuantityFromDonation(donation) }}
                {{ getTicketsQuantityFromDonation(donation) > 1 ? 'bilhetes' : 'bilhete'}}
            </strong>
        </div>
    </div>
</template>

<style scoped>
    div.donation {
        color: #333;
        font-size: 0.95rem;
    }

    div.donation .timestamp {
        margin-right: 15px;
        font-size: .8rem;
        color: #666;
    }

    div.donation .quantity {
        margin-left: 15px;
        font-weight: 500;
        font-size: 90%;
    }

    @media (min-width: 1400px) {
        div.donation {
            font-size: 1.2rem;
        }

        div.donation strong {
            font-size: 1rem;
        }
    }
</style>

<script>
    import DataHelper from "../../helpers/DataHelper";
    import "../../filters/default";
    import TicketsHelper from "../../helpers/TicketsHelper";

    export default {
        props: {
            donations: {
                type: Array,
                required: true
            },
            limit: {
                type: Number,
                default: 5
            }
        },
        data() {
            return {
                forceKey: 0,
                forceSeconds: 60
            }
        },
        methods: {
            getTicketsQuantityFromDonation(donation) {
                return TicketsHelper.getTicketsQuantityFromDonation(donation);
            },
            setTimeInterval() {
                window.setInterval(() => {
                    this.forceKey = (new Date()).getMinutes();
                }, this.forceSeconds * 1000);
            }
        },
        computed: {
            topDonations() {
                return DataHelper.getArrayTop(this.donations, this.limit);
            }
        },
        created() {
            this.setTimeInterval();
        }
    }
</script>


