<template>
    <div class="counter-container">
        <counter :value="counterInQuantity"></counter>
    </div>
</template>

<style scoped>
    div.counter-container {
        display: block;
        margin: auto auto;
        font-size: 8vh;
        font-weight: 500;
        color: #666;
        text-align: center;
    }
</style>

<script>
    import Counter from "./shared/Counter";
    import TicketsHelper from "../../helpers/TicketsHelper";

    export default {
        components: {
            Counter
        },
        props: {
            counter: {
                type: Number,
                required: true
            },
            duration: {
                type: Number,
                default: 4
            }
        },
        methods: {
            convertToQuantity(float) {
                return TicketsHelper.getTicketsQuantityFromAmount(float);
            }
        },
        computed: {
            counterInQuantity() {
                return parseInt(this.convertToQuantity(this.counter));
            }
        }
    }
</script>

