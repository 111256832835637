<template>
    <main class="app-theme">

        <navbar-app/>

        <template v-if="dateCampaign.isReady">

            <campaign-ended-app v-if="dateCampaign.ended"/>

            <template v-else>
                <notifications classes="notify-new-donation"
                               group="new-donation"
                               position="top left"
                               :width="notificationWidth"/>

                <div class="row">
                    <div class="column">
                        <banner-rotator/>
                    </div>
                    <div class="column">
                        <div class="cards-container">
                            <div class="card">
                                <div class="card-body center">
                                    <h5 class="card-title">Bilhetes Vendidos</h5>
                                    <tickets-counter v-if="campaign.aggregate && campaign.aggregate.sum_amount"
                                                     :counter="campaign.aggregate.sum_amount"/>
                                    <p v-else-if="!campaign.init" class="text-center text-muted">
                                        <font-awesome-icon icon="circle-notch" spin size="3x"/>
                                    </p>
                                    <p v-else class="text-center"><em>Não há compras</em></p>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-body center">
                                    <h5 class="card-title">Últimos Compradores</h5>
                                    <latest-tickets v-if="campaign.aggregate && campaign.aggregate.latest"
                                                    :donations="campaign.aggregate.latest"
                                                    :limit="5"/>
                                    <p v-else-if="!campaign.init" class="text-center text-muted">
                                        <font-awesome-icon icon="circle-notch" spin size="3x"/>
                                    </p>
                                    <p v-else class="text-center"><em>Não há compras</em></p>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-body center">
                                    <h5 class="card-title">Top Indicações</h5>
                                    <latest-referrals v-if="campaign.aggregate && campaign.aggregate.top_referrals_amount"
                                                      :referrals="campaign.aggregate.top_referrals_amount"
                                                      :value-formatter="getTicketsQuantityFromAmount"
                                                      :limit="10"/>
                                    <p v-else-if="!campaign.init" class="text-center text-muted">
                                        <font-awesome-icon icon="circle-notch" spin size="3x"/>
                                    </p>
                                    <p v-else class="text-center"><em>Não há compras</em></p>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-body center">
                                    <h5 class="card-title">Bilhetes Vendidos por Dia</h5>
                                    <bar-ticket-counter-by-date v-if="campaign.aggregate && campaign.aggregate.daily_amount"
                                                                :amounts="campaign.aggregate.daily_amount"
                                                                :limit="7"/>
                                    <p v-else-if="!campaign.init" class="text-center text-muted">
                                        <font-awesome-icon icon="circle-notch" spin size="3x"/>
                                    </p>
                                    <p v-else class="text-center"><em>Não há compras</em></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </main>
</template>

<script>
import CampaignMixin from "../../mixins/CampaignMixin";
import NavbarApp from "../../components/navbars/NavbarApp";
import TicketsCounter from "../../components/counters/TicketsCounter";
import LatestTickets from "../../components/lists/LatestTickets";
import BarTicketCounterByDate from "../../components/charts/BarTicketCounterByDate";
import BannerRotator from "../../components/banners/BannerRotator";
import LatestReferrals from "../../components/lists/LatestReferrals";
import TicketsHelper from "../../helpers/TicketsHelper";
import CampaignEndedApp from "../../components/warnings/EndedCampaignApp";

export default {
    mixins: [CampaignMixin],
    components: {
        CampaignEndedApp,
        LatestReferrals,
        BannerRotator,
        BarTicketCounterByDate,
        LatestTickets,
        TicketsCounter,
        NavbarApp
    },
    methods: {
        _hook_onNewDonationCreated(donation) {
            const quantity = TicketsHelper.getTicketsQuantityFromAmount(donation.amount);

            this.$notify({
                duration: 2000,
                group: 'new-donation',
                title: 'Nova compra',
                text: `${donation.donor.name} comprou ${quantity} bilhetes`
            });
        },
        getTicketsQuantityFromAmount(amount) {
            return TicketsHelper.getTicketsQuantityFromAmount(amount) + ' Bilhetes';
        }
    },
    computed: {
        notificationWidth() {
            return (window.innerWidth < 400 ? 300 : 400) + 'px';
        }
    }
}
</script>

